import {useState} from "react";
import Pagination from "../../components/pagination/pagination.component";

const PoliciesTable = ({policies, title, isRmdBdd}) => {
    const [currentPage, setCurrentPage] = useState({});
    const [itemsPerPage] = useState(10);

    // Group policies by advisor
    const groupedPolicies = policies.reduce((acc, policy) => {
        if (!acc[policy.advisor]) {
            acc[policy.advisor] = [];
        }
        acc[policy.advisor].push(policy);
        return acc;
    }, {});

    // Sort advisors by name
    const sortedGroupedPolicies = Object.entries(groupedPolicies)
        .sort(([advisorA], [advisorB]) => advisorA.localeCompare(advisorB))
        .reduce((acc, [advisor, policies]) => {
            acc[advisor] = policies;
            return acc;
        }, {});

    const paginate = (advisor, pageNumber) => {
        setCurrentPage((prev) => ({
            ...prev,
            [advisor]: pageNumber,
        }));
    };

    return (
        <div className="responsive__table__container">
            <h2 className="header__title">{title}</h2>
            {Object.entries(sortedGroupedPolicies).map(([advisor, policies]) => {
                const currentAdvisorPage = currentPage[advisor] || 1;
                const indexOfLastItem = currentAdvisorPage * itemsPerPage;
                const indexOfFirstItem = indexOfLastItem - itemsPerPage;
                const currentItems = policies.slice(indexOfFirstItem, indexOfLastItem);

                return (
                    <div key={advisor} className="advisor-section">
                        <h3 className="advisor-name">{advisor}</h3>
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col" style={{maxWidth: "200px", whiteSpace: "normal"}}>
                                    Company (Policy)
                                </th>
                                <th scope="col" className="text-right">
                                    Employees
                                </th>
                                <th scope="col" className="text-right">
                                    Activation Date
                                </th>
                                {!isRmdBdd && (
                                    <th scope="col" className="text-right">
                                        Plan Admin
                                    </th>
                                )}
                            </tr>
                            </thead>
                            <tbody>
                            {currentItems.map((item) => (
                                <tr key={item.id}>
                                    <td style={{maxWidth: "200px", whiteSpace: "normal"}}>
                                        {isRmdBdd ? (
                                            <span className="text-muted">{item.name}</span>
                                        ) : (
                                            <a
                                                href={`https://admin.nationalhealthclaim.com/ops/planadmin.policy.record?policy_id=${item.id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn btn-link text-decoration-none"
                                            >
                                                {item.name}
                                            </a>
                                        )}
                                    </td>
                                    <td className="text-right">{item.activeEmployees}</td>
                                    <td className="text-right">
                                        {item.activationDate ? new Date(item.activationDate).toISOString().split("T")[0] : ""}
                                    </td>
                                    {!isRmdBdd && (
                                        <td className="text-right">
                                            <a
                                                href={`https://admin.nationalhealthclaim.com/ops/planadmin.policy.record?policy_id=${item.id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="btn btn-link text-decoration-none"
                                            >
                                                View
                                            </a>
                                        </td>
                                    )}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {policies.length > itemsPerPage && (
                            <nav aria-label="Navigation">
                                <Pagination
                                    itemsPerPage={itemsPerPage}
                                    totalItems={policies.length}
                                    paginate={(pageNumber) => paginate(advisor, pageNumber)}
                                    currentPage={currentAdvisorPage}
                                />
                            </nav>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default PoliciesTable;
