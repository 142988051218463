import Header from "../../components/headerBlue/header.component";
import { SectionWrapper } from "../../components/section-wrapper/sectionWrapper.styles";

import { Link } from "react-router-dom";

const InvalidTokenPage = () => (
    <>
        <Header />
        <div className="page__container bg-white">
            <SectionWrapper>
                <h1 className="invalid__token__title text-center">Invalid Token</h1>
                <h2 className="text-center">
                    The token you provided is invalid or has expired.
                </h2>
                <h4 className="text-center">
                    Please <Link to="/signin">try signing in</Link> or <Link to="/resetpassword">request a new password</Link>.
                </h4>
            </SectionWrapper>
        </div>
    </>
);

export default InvalidTokenPage;