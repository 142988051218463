import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { useState, useEffect } from "react";

import { selectCurrentUser } from "../../redux/user/user.selectors";

import CompaniesSection from "../../components/companies/companies.component";

import { Spinner } from "../../components/spinner/spinner.component";
import { SectionWrapper } from "../../components/section-wrapper/sectionWrapper.styles";
import PageWrapper from "../../components/page-wrapper/page-wrapper.components";

const CompaniesPage = ({ currentUser }) => {
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const apiUrl = process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_API_URL_DEV
          : process.env.REACT_APP_API_URL_LOCAL;
  
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const accessToken = currentUser.token;
      const response = await fetch(
        `${apiUrl}/advisors/${currentUser.id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setIsLoading(false);
        setUserInfo(data);
      } else {
        setIsLoading(false);
        throw new Error(response.statusText);
      }
    } catch (err) {
      setLoadingError(true);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      {!isLoading ? (
        Object.keys(userInfo).length !== 0 && !loadingError ? (
          <div className="user__page-container">
            <CompaniesSection companies={userInfo.companies} />
          </div>
        ) : (
          <SectionWrapper>
            <h1 className="text-center">
              An error has occured while retrieving data. Please refresh the
              page and try again.
            </h1>
          </SectionWrapper>
        )
      ) : (
        <Spinner />
      )}
    </PageWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(CompaniesPage);
