import {useState} from "react";

import Button from "react-bootstrap/Button";

import {CSVLink} from "react-csv";
import DatePicker from "react-datepicker";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import toastr from "toastr";

import DownLoadCSV from "../../assets/csv.png";
import DownLoadPDF from "../../assets/pdf.png";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {selectCurrentDistro} from "../../redux/distro/distro.selector";

import {selectCurrentUser} from "../../redux/user/user.selectors";
import DistributionSummaryTotal from "../distribution-summary-total/distribution-summary-total.component";
import DistributionSummary from "../distribution-summary/distribution-summary.component";
import {SectionWrapper} from "../section-wrapper/sectionWrapper.styles";

import {Spinner} from "../spinner/spinner.component";

import {formatData, generatePDF} from "./utils";
import "./distribution.styles.scss";

const DistributionSection = ({currentUser, currentDistro}) => {
    const [groupOption, setOption] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [distributionInfo, setDistributionInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const apiUrl = process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_API_URL_DEV
            : process.env.REACT_APP_API_URL_LOCAL;
    const portal = () => {
        const {innerWidth: width} = window;
        if (width <= 500) {
            return true;
        } else {
            return false;
        }
    };

    const fetchData = async (groupOption, month, year) => {
        try {
            setIsLoading(true);
            const accessToken = currentUser.token;
            const response = await fetch(
                `${apiUrl}/distribution/${currentDistro}/${groupOption}/${year.getUTCFullYear()}/${month.getMonth()}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.ok) {
                const data = await response.json();
                setDistributionInfo(data);
                setIsLoading(false);
                if (Array.isArray(data) && data.length === 0) {
                    toastr.warning("No commission report found for this date range. Please check back later.");
                }
            } else {
                setIsLoading(false);
                throw new Error(response.statusText);
            }
        } catch (err) {
            toastr.warning("No commission report found for this date range. Please check back later.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (month && year && groupOption !== "") {
            fetchData(groupOption, month, year);
        } else {
            toastr.warning("Please select all fields.");
        }
    };

    return (
        <SectionWrapper title={"Commissions"}>
            <div className="section__container">
                <form
                    className="filter__form__container__distribution"
                    onSubmit={handleSubmit}
                >
                    <select
                        required
                        name="group_by"
                        className="filter__input__distribution"
                        onChange={(e) => setOption(e.target.value)}
                    >
                        <option className="filter__input_value" value="">
                            Group By
                        </option>
                        <option value="company"> Group by Company</option>
                        <option value="advisor">Group by Advisor</option>
                        {/*<option value="commission">Group by Product</option>*/}
                    </select>
                    <div className="filter__input__options__container">
                        <div className="month">
                            <DatePicker
                                className="filter__input__distribution month"
                                selected={month}
                                onChange={(date) => setMonth(date)}
                                dateFormat="MM"
                                showMonthYearPicker
                                showMonthDropdown
                                placeholderText="Month"
                                required
                                withPortal={portal()}
                                disabledKeyboardNavigation={portal()}
                            />
                        </div>

                        <DatePicker
                            className="filter__input__distribution"
                            selected={year}
                            onChange={(date) => setYear(date)}
                            showYearPicker
                            dateFormat="yyyy"
                            placeholderText="Year"
                            required
                            withPortal={portal()}
                            disabledKeyboardNavigation={portal()}
                        />
                    </div>
                    <Button
                        className="py-2 px-4"
                        variant="primary"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={groupOption === "" || month === "" || year === ""}
                    >
                        Submit
                    </Button>
                </form>
                <div className="tables__container">
                    {Object.keys(distributionInfo).length > 0 ? (
                        Object.keys(distributionInfo).length !== 5 ? (
                            <DistributionSummary
                                key={"distributionSummary"}
                                distribution={distributionInfo}
                            />
                        ) : (
                            <DistributionSummaryTotal
                                key={"distributionSummaryTotal"}
                                spendingAccountsTotal={distributionInfo.spendingAccountsTotal}
                                teladocTotal={distributionInfo.teladocTotal}
                                corehealthTotal={distributionInfo.corehealthTotal}
                                total={distributionInfo.total}
                            />
                        )
                    ) : null}
                    {isLoading ? (
                        <div className="financial__spinner">
                            <Spinner className="spin" style={{display: "none"}}/>
                        </div>
                    ) : null}
                </div>
            </div>
            {Object.keys(distributionInfo).length > 0 ? (
                <div className="download__container">
                    <h2 className="header__title">Download summary report:</h2>
                    <div>
                        <CSVLink
                            data={formatData(distributionInfo)}
                            filename={`Summary Report by ${groupOption}.csv`}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Download CSV"
                            trigger="hover"
                        >
                            <img src={DownLoadCSV} alt="CSV" className="download-icon"/>
                        </CSVLink>
                        <span
                            onClick={() =>
                                generatePDF(
                                    distributionInfo,
                                    month.getMonth(),
                                    year.getUTCFullYear()
                                )
                            }
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Download PDF"
                            trigger="hover"
                        >
              <img src={DownLoadPDF} alt="PDF" className="download-icon"/>
            </span>
                    </div>
                </div>
            ) : null}
        </SectionWrapper>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    currentDistro: selectCurrentDistro,
});

export default connect(mapStateToProps)(DistributionSection);
