import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectCurrentUser } from "../../redux/user/user.selectors";
import { selectCurrentDistro } from "../../redux/distro/distro.selector";

import AdvisorInfoPage from "./advisor-info-page.component";
import OwnerInfoPage from "./owner-info-page.component";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

const UserProfile = ({ currentUser, currentDistro }) =>
  currentUser.role === "owner" ? <OwnerInfoPage /> : <AdvisorInfoPage />;

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentDistro: selectCurrentDistro,
});

export default connect(mapStateToProps)(UserProfile);
