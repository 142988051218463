import {useState} from "react";
import {Link} from "react-router-dom";

import Pagination from "../pagination/pagination.component";
import {SectionWrapper} from "../section-wrapper/sectionWrapper.styles";

const CompaniesSection = ({companies}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    //Get current posts
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = companies.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => {
        if (pageNumber === 0) {
            setCurrentPage(() => currentPage - 1);
        } else if (pageNumber > companies.length) {
            setCurrentPage(currentPage + 1);
        } else {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <SectionWrapper title={"Clients"}>
            <div className="responsive__table__container">
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Company</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentItems.length
                        ? currentItems.map((company, index) => {
                            return (
                                <tr key={company + index}>
                                    <th scope="row" key={company.id}>
                                        {company.name}
                                    </th>
                                    <td>
                                        <Link
                                            to={{
                                                pathname: `https://admin.nationalhealthclaim.com/ops/planadmin.policy.record?policy_id=${company.id}`,
                                            }}
                                            target="_blank"
                                        >
                                            View
                                        </Link>
                                    </td>
                                </tr>
                            );
                        })
                        : null}
                    </tbody>
                </table>
            </div>
            {companies.length > itemsPerPage ? (
                <nav aria-label="Advisors navigation">
                    <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={companies.length}
                        paginate={paginate}
                        currentPage={currentPage}
                    />
                </nav>
            ) : null}
        </SectionWrapper>
    );
};

export default CompaniesSection;
