import {useState} from "react";
import Pagination from "../pagination/pagination.component";
import {SectionWrapper} from "../section-wrapper/sectionWrapper.styles";

const AdvisorsSection = ({advisors}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = advisors.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => {
        if (pageNumber === 0) {
            setCurrentPage((prev) => prev - 1);
        } else if (pageNumber > Math.ceil(advisors.length / itemsPerPage)) {
            setCurrentPage((prev) => prev + 1);
        } else {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <SectionWrapper title="Advisors">
            <div className="table-responsive">
                <div className="advisors-table-container">
                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Advisor ID</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Relationship</th>
                            <th scope="col">Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentItems.map((item) =>
                                item.advisorId && (
                                    <tr key={item.id}>
                                        <th scope="row">{item.advisorId}</th>
                                        <td>{item.lastName}</td>
                                        <td>{item.firstName}</td>
                                        <td>{item.email}</td>
                                        <td>{item.relationship}</td>
                                        <td>{item.description}</td>
                                    </tr>
                                )
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
            {advisors.length > itemsPerPage && (
                <nav aria-label="Advisors navigation" className="mt-3">
                    <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={advisors.length}
                        paginate={paginate}
                        currentPage={currentPage}
                    />
                </nav>
            )}
        </SectionWrapper>
    );
};

export default AdvisorsSection;