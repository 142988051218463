import {useState} from "react";
import {Link} from "react-router-dom";

import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import logo from "../../assets/nhclogo.png";

import "./sign-in-page.styles.scss";

const ResetPasswordPage = () => {
    const initialState = {
        email: "",
        error: false,
        message: null,
        success: false
    };

    const [user, setUser] = useState({...initialState});
    const apiUrl = process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_API_URL_DEV
            : process.env.REACT_APP_API_URL_LOCAL;

    const handleChange = (e) => {
        e.target.name === "email"
            ? setUser({...user, email: e.target.value})
            : setUser({...user});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const requestOptions = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    email: user.email,
                }),
            };
            const response = await fetch(
                `${apiUrl}/users/requestpassword`,
                requestOptions
            );

            if (response.ok) {
                await response
                    .json()
                    .then((data) => {
                            if (data.success) {
                                setUser({...user, message: data.message, success: true});
                            } else {
                                setUser({...user, message: data.message, success: false});
                            }
                        }
                    );
            } else {
                const data = await response.json();
                setUser({...user, message: data.message, success: false});
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="page__container">
            <div className="form__container">
                <img className="logo" src={logo} alt="Logo"/>
                <h4 className="message">Reset or Setup password</h4>
                <form className="signin__form" onSubmit={handleSubmit}>
                    <input
                        className="signin__input"
                        name="email"
                        type="email"
                        onChange={handleChange}
                        value={user.email}
                        placeholder="Email"
                        required
                    />

                    {user.success === true && user.message !== null ? (
                        <Alert key={"alert"} variant={"success"}>
                            {user.message}
                        </Alert>
                    ) : null}

                    {user.success === false && user.message !== null ? (
                        <Alert key={"alert"} variant={"danger"}>
                            {user.message}
                        </Alert>
                    ) : null}

                    <Button className="signin__button" variant="primary" type="submit">
                        Submit
                    </Button>
                </form>
                <span className="link__container">
          <Link className="link" to="/signin">
            Return to login
          </Link>
        </span>
            </div>
        </div>
    );
};

export default ResetPasswordPage;
