import {useEffect, useState} from "react";
import {Button, Card, Modal} from 'react-bootstrap';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {FaPlus} from "react-icons/fa";
import {TbFileCheck} from "react-icons/tb";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {EnrollmentTable} from "../../components/enrollments/enrollments.component";
import PageWrapper from "../../components/page-wrapper/page-wrapper.components";
import {SectionWrapper} from "../../components/section-wrapper/sectionWrapper.styles";
import {Spinner} from "../../components/spinner/spinner.component";
import {selectCurrentUser} from "../../redux/user/user.selectors";

const EnrollmentsDashboardPage = ({currentUser}) => {

    const [enrollments, setEnrollments] = useState([]);
    const [createStep, setCreateStep] = useState(1);
    const [showQuickEnrollmentModal, setShowQuickEnrollmentModal] = useState(false);
    const [showRegularEnrollmentModal, setShowRegularEnrollmentModal] = useState(false);
    const [showEnrollmentModal, setShowEnrollmentModal] = useState(false);
    const [showPromptModal, setShowPromptModal] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState("hsa");

    const [companyInfo, setCompanyInfo] = useState({
        companyName: "",
        dba: "",
        address1: "",
        address2: "",
        city: "",
        province: "",
        companyPostal: "",
        brokerName: "",
        brokerEmail: "",
    });

    const [enrollInfo, setEnrollInfo] = useState({
        clientFirstName: "",
        clientLastName: "",
        clientEmail: "",
    });

    const [policyName, setPolicyName] = useState("");
    const [adminFee, setAdminFee] = useState(10);
    const [enrollmentFee, setEnrollmentFee] = useState("");
    const [advisorFirstName, setAdvisorFirstName] = useState("");
    const [advisorLastName, setAdvisorLastName] = useState("");
    const [advisorEmail, setAdvisorEmail] = useState("");
    const [companyPostal, setPostalCode] = useState(companyInfo.companyPostal);
    const [bankPostal, setBankPostalCode] = useState("");
    const [isValidCompanyPostalCode, setIsValidCompanyPostalCode] = useState(true);
    const [isValidBankPostalCode, setIsValidBankPostalCode] = useState(true);
    const [isPostalCodeTouched, setIsPostalCodeTouched] = useState(false);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [enrollmentToDelete, setEnrollmentToDelete] = useState(null);
    const [filterStatus, setFilterStatus] = useState("Show all");
    const [sortCriteria, setSortCriteria] = useState("createdAtDesc");

    const [fundingOption, setFundingOption] = useState({
        type: "",
        details: {},
    });

    const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL_PROD : process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LOCAL;

    const fetchEnrollments = async () => {
        try {
            setIsLoading(true);
            const accessToken = currentUser.token;
            const response = await fetch(
                `${apiUrl}/enrollment/${currentUser.id}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            if (response.ok) {
                const data = await response.json();

                setEnrollments(data.enrollments);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                throw new Error(response.statusText);
            }
        } catch (err) {
            setLoadingError(true);
        }
    };

    useEffect(() => {
        fetchEnrollments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFilterChange = (e) => {
        setFilterStatus(e.target.value);
    };

    const handleSortChange = (e) => {
        setSortCriteria(e.target.value);
    };

    const filteredEnrollments = enrollments.filter((item) => {
        if (filterStatus === "Show all") return true;
        return item.status.toLowerCase() === filterStatus.split(" ")[1];
    });

    const sortedEnrollments = filteredEnrollments.sort((a, b) => {
        if (sortCriteria.includes("createdAt")) {
            return sortCriteria === "createdAtAsc" ? new Date(a.createdAt) - new Date(b.createdAt) : new Date(b.createdAt) - new Date(a.createdAt);
        } else {
            return sortCriteria === "policyNameAsc" ? a.policyName.localeCompare(b.policyName) : b.policyName.localeCompare(a.policyName);
        }
    });

    const deleteEnrollment = async (id) => {
        try {
            const response = await fetch(`${apiUrl}/enrollment/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${currentUser.token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            fetchEnrollments();
        } catch (error) {
            console.error('Error:', error);
        }
    }

    let createEnrollment = () => {
        //setShowPromptModal(true);
        setShowRegularEnrollmentModal(true);
    }
    const handleClose = () => {
        setShowEnrollmentModal(false);
        setShowRegularEnrollmentModal(false);
        resetForm();
    }

    let resetForm = () => {
        setCreateStep(1);
        setSelectedPlan("hsa");
        setCompanyInfo({
            companyName: "",
            dba: "",
            address1: "",
            address2: "",
            city: "",
            province: "",
            companyPostal: "",
            brokerName: "",
            brokerEmail: "",
        });
        setEnrollInfo({
            clientFirstName: "",
            clientLastName: "",
            clientEmail: "",
        });
    }

    let processNextStep = () => {
        if (createStep === 1) {
            setCreateStep(2);
        } else if (createStep === 2) {
            setCreateStep(3);
        }
    }

    let processPrevStep = () => {
        if (createStep === 2) {
            setCreateStep(1);
        } else if (createStep === 3) {
            setCreateStep(2);
        }
    }

    const handleCompanyInfoChange = (e) => {
        const {id, value} = e.target;
        setCompanyInfo((prev) => ({
            ...prev,
            [id]: value,
        }));
    };

    const handleEnrollInfoChange = (e) => {
        const {id, value} = e.target;
        setEnrollInfo((prev) => ({
            ...prev,
            [id]: value,
        }));
    };

    const postalCodeCheck = (event, name) => {
        const value = event.target.value;
        const postalCodeRegex = /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/;
        const isValid = postalCodeRegex.test(value);

        if (name === "companyPostal") {
            setIsValidCompanyPostalCode(isValid);
            setPostalCode(value);
            handleCompanyInfoChange({
                target: {
                    id: "companyPostal",
                    value,
                },
            });
        } else if (name === "bankPostal") {
            setIsValidBankPostalCode(isValid);
            setBankPostalCode(value);
            if (isValid) {
                setFundingOption((prevState) => ({
                    ...prevState,
                    details: {...prevState.details, bankPostalCode: value},
                }));
            }
        }
    };

    const handlePostalCodeBlur = () => {
        setIsPostalCodeTouched(true);
    };

    const handleSubmit = async (sendEmail) => {

        const allData = {
            companyInfo,
            enrollInfo,
            selectedPlan,
            enrollmentFee
            // categories,
            // accountConfig,
            // fundingOption,
            // employees,
        };

        try {
            setIsLoading(true);
            const accessToken = currentUser.token;
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                    id: currentUser.id,
                    policyName,
                    selectedPlan,
                    adminFee,
                    enrollmentFee,
                    enrollmentData: JSON.stringify(allData),
                    sendEmail: sendEmail,
                }),
            };
            const response = await fetch(`${apiUrl}/enrollment/create`, requestOptions);
            if (response.ok) {
                const data = await response.json();
                if (!sendEmail) {
                    let enrollmentId = data.enrollmentId;
                    window.open(`/enroll/${enrollmentId}?mode=edit`, "_blank")
                }
                handleClose();
                setIsLoading(false);
                fetchEnrollments();
            } else {
                setIsLoading(false);
                throw new Error(response.statusText);
            }
        } catch (err) {
            setLoadingError(true);
        }
    };

    const handleDeleteClick = (id) => {
        setEnrollmentToDelete(id);
        setShowDeleteModal(true);
    }

    const handleConfirmDelete = () => {
        deleteEnrollment(enrollmentToDelete);
        setShowDeleteModal(false);
    }

    return !isLoading ? (
        <PageWrapper>
            <SectionWrapper title={"Policy Enrollments"}>
                <div className="enrollment-info-container pb-0">
                    <div className="create-enrollment-button">
                        <button className="px-4 btn btn-primary"
                                onClick={createEnrollment}
                                style={{minWidth: 50}}>
                            <span className="d-none d-sm-inline me-2">Create</span>
                            <FaPlus className="d-inline-block"/>
                        </button>
                    </div>
                    <div className="table-and-controls-wrapper">
                        {enrollments.length === 0 ? (
                            <div>There are currently no enrollments pending. <a href="#" onClick={createEnrollment}>Click
                                here</a> to begin the enrollment process.</div>
                        ) : (
                            <div className="enrollment-table-container">
                                <div className="controls-container">
                                    <div className="filter-container">
                                        <label htmlFor="filter-select">Filter:</label>
                                        <select id="filter-select" className="form-select" value={filterStatus}
                                                onChange={handleFilterChange}>
                                            <option value="Show all">Show all</option>
                                            <option value="Show pending">Show unsigned</option>
                                            <option value="Show signed">Show signed</option>
                                            <option value="Show complete">Show enrolled</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="table-scroll-container">
                                    <EnrollmentTable fetchEnrollments={fetchEnrollments}
                                                     currentItems={filteredEnrollments}
                                                     handleDeleteClick={handleDeleteClick}
                                                     currentUser={currentUser}
                                    />
                                </div>
                            </div>

                        )}
                    </div>
                </div>
            </SectionWrapper>
            <Modal className={"quick-enroll-modal"} show={showRegularEnrollmentModal}
                   onHide={() => setShowRegularEnrollmentModal(false)} dialogClassName="modal-80h">
                <Modal.Header closeButton>
                    <Modal.Title>Generate Enrollment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-section scrollable-content" style={{fontSize: 14}}>
                        <div className={"mb-lg-4"}>
                            Enter your client's company name, plan, admin fee, and client details to generate an
                            enrollment
                            form. You can fill out as much information as you have available now, and your client can
                            complete the rest later.
                        </div>
                        <div className="form-section">
                            <Row className="mb-3">
                                <Form.Group as={Col} md={12}>
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        id="policyName"
                                        type="text"
                                        onChange={(e) => setPolicyName(e.target.value)}
                                        value={policyName}
                                        required
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={6}>
                                    <Form.Label>Total Admin Fee</Form.Label>
                                    <Form.Select value={adminFee} onChange={(e) => setAdminFee(e.target.value)}>
                                        <option value={5}>5%</option>
                                        <option value={5.5}>5.5%</option>
                                        <option value={6}>6%</option>
                                        <option value={6.5}>6.5%</option>
                                        <option value={7}>7%</option>
                                        <option value={7.5}>7.5%</option>
                                        <option value={8}>8%</option>
                                        <option value={8.5}>8.5%</option>
                                        <option value={9}>9%</option>
                                        <option value={9.5}>9.5%</option>
                                        <option value={10}>10%</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                    <Form.Label>Enrollment Fee</Form.Label>
                                    <div style={{position: "relative"}}>
                                        <span style={{
                                            position: "absolute",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            left: "10px",
                                            pointerEvents: "none"
                                        }}>$</span>
                                        <Form.Control type="text" style={{paddingLeft: "25px"}}
                                                      id="enrollmentFee"
                                                      value={enrollmentFee}
                                                      onChange={event => setEnrollmentFee(event.target.value)}
                                                      onKeyDown={(e) => {
                                                          // Allow only valid input (numbers, decimals, backspace and tab)
                                                          const char = e.key;
                                                          if (char !== "." && char !== "Backspace" && char !== "Tab" && (char < "0" || char > "9")) {
                                                              e.preventDefault();
                                                          }
                                                      }}
                                        />
                                    </div>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={4}>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        id="clientFirstName"
                                        type="text"
                                        onChange={handleEnrollInfoChange}
                                        value={enrollInfo?.clientFirstName ?? ''}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={4}>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        id="clientLastName"
                                        type="text"
                                        onChange={handleEnrollInfoChange}
                                        value={enrollInfo?.clientLastName ?? ''}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={4}>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        id="clientEmail"
                                        type="email"
                                        onChange={handleEnrollInfoChange}
                                        value={enrollInfo?.clientEmail ?? ''}
                                        required
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <div className={"my-3"}>
                                    <div className={"mb-2"}>Select Plan</div>
                                    <div className="select-plan-buttons">
                                        <Card
                                            className={`plan-button-main ${selectedPlan === "hsa" ? "plan-button-selected" : ""}`}
                                            onClick={() => {
                                                setSelectedPlan("hsa");
                                            }}>
                                            <Card.Body className="card-body-centered">
                                                <Card.Title className="card-title-centered card-label-main">Health
                                                    Spending
                                                    Account</Card.Title>
                                            </Card.Body>
                                        </Card>
                                        <Card
                                            className={`plan-button-main ${selectedPlan === "lsa" ? "plan-button-selected" : ""}`}
                                            onClick={() => {
                                                setSelectedPlan("lsa");
                                            }}>
                                            <Card.Body className="card-body-centered">
                                                <Card.Title className="card-title-centered card-label-main">Lifestyle
                                                    Spending
                                                    Account</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="select-plan-buttons">
                                        <Card
                                            className={`plan-button-main ${selectedPlan === "fixed" ? "plan-button-selected" : ""}`}
                                            onClick={() => {
                                                setSelectedPlan("fixed");
                                            }}>
                                            <Card.Body className="card-body-centered">
                                                <Card.Title className="card-title-centered card-label-main">HSA + LSA
                                                    (Fixed)</Card.Title>
                                            </Card.Body>
                                        </Card>
                                        <Card
                                            className={`plan-button-main ${selectedPlan === "flex" ? "plan-button-selected" : ""}`}
                                            onClick={() => {
                                                setSelectedPlan("flex");
                                            }}>
                                            <Card.Body className="card-body-centered">
                                                <Card.Title className="card-title-centered card-label-main">Flexible
                                                    Spending
                                                    Account</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <div style={{color: "gray"}}>
                            <div className="icon-text">
                                <TbFileCheck/>
                                <div>
                                    After the enrollment form is completed, National HealthClaim (NHC) will be
                                    notified and will begin the review process. If there are any questions, NHC may
                                    reach
                                    out to the company's Plan Administrator or Advisor for further discussion.
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRegularEnrollmentModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="success"
                        size="md"
                        onClick={async (e) => {
                            e.preventDefault();
                            await handleSubmit(false);
                        }}
                        style={{minWidth: '120px'}} disabled={
                        !enrollInfo.clientFirstName ||
                        !enrollInfo.clientLastName ||
                        !enrollInfo.clientEmail ||
                        !policyName ||
                        adminFee <= 0 ||
                        !enrollmentFee
                    }
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this enrollment?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageWrapper>) : (
        <Spinner/>
    );
}


const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(EnrollmentsDashboardPage);