import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import {createStructuredSelector} from "reselect";
import CommissionsTable from "../../components/commissions/commisions.component";

import {formatDate} from "../../components/financials/utils";

import "react-datepicker/dist/react-datepicker.css";

import PageWrapper from "../../components/page-wrapper/page-wrapper.components";
import {SectionWrapper} from "../../components/section-wrapper/sectionWrapper.styles";
import {Spinner} from "../../components/spinner/spinner.component";
import {selectCurrentDistro} from "../../redux/distro/distro.selector";

import {selectCurrentUser} from "../../redux/user/user.selectors";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import PoliciesTable from "./policies-page";

const ClientsSection = ({currentUser, currentDistro}) => {
    const [financialInfo, setFinancialInfo] = useState({
        commission: [],
        summary: [],
    });
    const [policies, setPolicies] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isRmdBdd, setIsRmdBdd] = useState(false);
    const apiUrl = process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_API_URL_DEV
            : process.env.REACT_APP_API_URL_LOCAL;

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const clientType = queryParams.get('type');

    const fetchData = async (startingDate, endingDate) => {
        try {
            setIsLoading(true);
            const accessToken = currentUser.token;

            // Fetch financials
            const resFinancials = await fetch(
                `${apiUrl}/financials/${currentDistro}/${formatDate(startingDate)}/${formatDate(endingDate)}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            // Fetch policies
            const resPolicies = await fetch(
                `${apiUrl}/financials/policies/${currentDistro}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (resFinancials.ok && resPolicies.ok) {
                const financialData = await resFinancials.json();
                const policiesData = await resPolicies.json();
                setIsLoading(false);
                if (isLoading === false) {
                    setFinancialInfo(financialData);
                    setPolicies(policiesData.uniquePolicies);
                    setIsRmdBdd(policiesData.isRmdBdd);
                }
            } else {
                setIsLoading(false);
                throw new Error(resFinancials.statusText || resPolicies.statusText);
            }
        } catch (err) {
            localStorage.clear();
            alert(
                "An error has occured while retrieving data. Please refresh the page and try again."
            );
        }
    };

    useEffect(() => {
        let defaultEndDate = new Date();
        defaultEndDate.setDate(1);
        defaultEndDate.setHours(-1);
        let defaultStarDate = new Date(defaultEndDate.getFullYear(), defaultEndDate.getMonth(), 1);
        if (currentDistro !== null) {
            fetchData(defaultStarDate, defaultEndDate);
        }
        return (isLoading) => (isLoading = true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDistro]);

    return (
        <PageWrapper>
            <SectionWrapper title={`Clients (Total ${policies.length})`}>
                <div className="section__container">
                    <div className="tables__container">
                        {
                            clientType === "policy" ? (
                                <PoliciesTable isRmdBdd={isRmdBdd} title={""} policies={policies}></PoliciesTable>
                            ) : (
                                financialInfo.commission.length > 0 ? (
                                    <CommissionsTable commissions={financialInfo.commission}/>
                                ) : null
                            )
                        }

                        {isLoading ? (
                            <div className="financial__spinner">
                                <Spinner className="spin" style={{display: "none"}}/>
                            </div>
                        ) : null}
                    </div>
                </div>
            </SectionWrapper>
        </PageWrapper>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    currentDistro: selectCurrentDistro,
});

export default connect(mapStateToProps)(ClientsSection);
