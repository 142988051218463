import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { useState, useEffect } from "react";

import { selectCurrentUser } from "../../redux/user/user.selectors";
import { setCurrentDistro } from "../../redux/distro/distro.actions";
import { selectCurrentDistro } from "../../redux/distro/distro.selector";

import PageWrapper from "../../components/page-wrapper/page-wrapper.components";
import AdvisorsSection from "../../components/advisors/advisors.component";
import { Spinner } from "../../components/spinner/spinner.component";
import { SectionWrapper } from "../../components/section-wrapper/sectionWrapper.styles";

const AdvisorsPage = ({ currentUser, currentDistro, setCurrentDistro }) => {
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const apiUrl = process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_API_URL_DEV
          : process.env.REACT_APP_API_URL_LOCAL;

  const fetchData = async (distroId) => {
    try {
      setIsLoading(true);
      const accessToken = currentUser.token;
      const response = await fetch(
        `${apiUrl}/users/${currentUser.id}/${distroId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setUserInfo(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setLoadingError(false);
        throw new Error(response.statusText);
      }
    } catch (err) {
      setLoadingError(true);
    }
  };

  useEffect(() => {
    if (currentUser.distros.length === 1) {
      setCurrentDistro(currentUser.distros[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentDistro !== null) {
      fetchData(currentDistro);
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDistro]);

  return (
    <PageWrapper>
      {!isLoading ? (
        Object.entries(userInfo).length > 0 && !loadingError ? (
          <AdvisorsSection advisors={userInfo.advisors} />
        ) : (
          <SectionWrapper>
            <h1 className="text-center">
              An error has occured while retrieving data. Please refresh the
              page and try again.
            </h1>
          </SectionWrapper>
        )
      ) : (
        <Spinner />
      )}
    </PageWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentDistro: selectCurrentDistro,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentDistro: (data) => dispatch(setCurrentDistro(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvisorsPage);
