import jsPDF from "jspdf";
import "jspdf-autotable";

import Logo from "../../assets/nhclogo.png";

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const formatDate = (customDate) => {
    const year = customDate.getFullYear();
    const month = ("0" + (customDate.getMonth() + 1)).slice(-2);
    const day = ("0" + customDate.getDate()).slice(-2);
    const formatedDate = `${year}-${month}-${day}`;
    return formatedDate;
};

export const formatData = (report) => {
    let tableData = [];
    if (Object.keys(report).length === 5) {
        Object.keys(report).forEach((key) => {
            if (key === "spendingAccounts" || key === "teladoc" || key === "corehealth") {
                tableData.push(...report[key]);
            } else if (key === "teladocTotal" || key === "corehealthTotal" || key === "spendingAccountsTotal") {
                if (parseFloat(report[key]) > 0) {
                    tableData.push({[key]: report[key]});
                }
            } else {
                tableData.push({[key]: report[key]});
            }
        });
    } else if (report.length > 0) {
        report.forEach((item) => {
            Object.keys(item).forEach((key) => {
                if (key === "spendingAccounts" || key === "teladoc" || key === "corehealth") {
                    tableData.push(...item[key]);
                } else if (key === "teladocTotal" || key === "corehealthTotal" || key === "spendingAccountsTotal") {
                    if (parseFloat(item[key]) > 0) {
                        tableData.push({[key]: item[key]});
                    }
                } else {
                    tableData.push({[key]: item[key]});
                }
            });
        });
    }

    return tableData;
};

export const generatePDF = (report, month, year) => {
    // initialize jsPDF
    const doc = new jsPDF();
    let pageSize = doc.internal.pageSize;
    let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    jsPDF.autoTableSetDefaults({
        theme: "striped",
        tableWidth: "auto",
        columnWidth: "auto",
        showHead: "everyPage",
        rowPageBreak: "avoid",
        pageBreak: "auto",
        didDrawPage: function (data) {
            //header
            doc.addImage(Logo, "PNG", 115, 5, 90, 7);

            //footer
            let str = `${doc.internal.getNumberOfPages()}`;
            doc.setFontSize(10);
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height
                ? pageSize.height
                : pageSize.getHeight();
            const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
            doc.text(str, pageWidth / 2, pageHeight - 2);
        },
        margin: {top: 30, bottom: 20},
        styles: {
            overflow: "linebreak",
        },
        columnStyles: {1: {halign: "center", cellWidth: 20}},
        headStyles: {fillColor: "#223e7f"},
    });

    const width = doc.internal.pageSize.getWidth();
    doc.text(`Distribution report ${months[month]} ${year}`, width / 2, 25, {
        align: "center",
    });

    if (Object.keys(report).length === 5) {
        const tableColumnSummary = ["Type", "Amount"];
        const summaryRows = [];

        let summaryData = [];
        if (parseFloat(report.spendingAccountsTotal) > 0) {
            summaryData = ["Spending Accounts Total", `$${report.spendingAccountsTotal}`];
            summaryRows.push(summaryData);
        }

        if (parseFloat(report.teladocTotal) > 0) {
            let teladoc = ["Teladoc Total", `$${report.teladocTotal}`];
            summaryRows.push(teladoc);
        }

        if (parseFloat(report.corehealthTotal) > 0) {
            let corehealth = ["Corehealth Total", `$${report.corehealthTotal}`];
            summaryRows.push(corehealth);
        }

        summaryData = ["Total", `$${report.total}`];
        summaryRows.push(summaryData);

        doc.setFontSize(12);
        doc.text("Summary report", 15, 40);
        doc.autoTable({
            columns: tableColumnSummary,
            body: summaryRows,
            startY: 45,
            columnStyles: {1: {halign: 'right'}}
            // tableWidth: 100,
        });

        const tableHeaders = ["Description", "Amount", "Date"];

        let finalY = doc.previousAutoTable.finalY;

        doc.setFontSize(12);

        if (report.spendingAccounts.length > 0) {
            const spendingAccountsRows = [];

            report.spendingAccounts.forEach((item) => {
                const spendingAccountsData = [
                    item.description,
                    `$${item.amount}`,
                    item.date,
                ];
                spendingAccountsRows.push(spendingAccountsData);
            });

            finalY = doc.previousAutoTable.finalY;

            doc.setFontSize(12);
            doc.text("Spending Accounts Report", 15, finalY + 15);
            doc.autoTable({
                columns: tableHeaders,
                body: spendingAccountsRows,
                startY: finalY + 20,
                columnStyles: {1: {halign: 'right'}}
            });
        }

        if (report.teladoc.length > 0) {
            const teladocRows = [];

            report.teladoc.forEach((item) => {
                const teladocData = [
                    item.description,
                    `$${item.amount}`,
                    item.date,
                ];
                teladocRows.push(teladocData);
            });

            finalY = doc.previousAutoTable.finalY;

            doc.setFontSize(12);
            doc.text("Teladoc Report", 15, finalY + 15);
            doc.autoTable({
                columns: tableHeaders,
                body: teladocRows,
                startY: finalY + 20,
                columnStyles: {1: {halign: 'right'}}
            });
        }

        if (report.corehealth.length > 0) {
            const corehealthRows = [];

            report.corehealth.forEach((item) => {
                const corehealthData = [
                    item.description,
                    `$${item.amount}`,
                    item.date,
                ];
                corehealthRows.push(corehealthData);
            });

            finalY = doc.previousAutoTable.finalY;

            doc.setFontSize(12);
            doc.text("Corehealth Report", 15, finalY + 15);
            doc.autoTable({
                columns: tableHeaders,
                body: corehealthRows,
                startY: finalY + 20,
                columnStyles: {1: {halign: 'right'}}
            });
        }

    } else {
        report.forEach((item, idx) => {
            let finalY = 20;
            if (idx !== 0) {
                finalY = doc.previousAutoTable.finalY;
                if (pageHeight - finalY < 100) {
                    doc.addPage();
                    finalY = 20;
                }
            }
            const name = item.company ? "Company" : "Agent";
            doc.setFontSize(15);
            doc.text(
                `${name}: ${item.company || item.agentReportingCode}`,
                15,
                finalY + 20
            );
            const tableColumnSummary = ["Type", "Amount"];
            const summaryRows = [];

            let summaryData = [];
            if (parseFloat(item.spendingAccountsTotal) > 0) {
                summaryData = ["Spending Accounts Total", `$${item.spendingAccountsTotal}`];
                summaryRows.push(summaryData);
            }

            if (parseFloat(item.teladocTotal) > 0) {
                let teladoc = ["Teladoc Total", `$${item.teladocTotal}`];
                summaryRows.push(teladoc);
            }

            if (parseFloat(item.corehealthTotal) > 0) {
                let corehealth = ["Corehealth Total", `$${item.corehealthTotal}`];
                summaryRows.push(corehealth);
            }

            summaryData = ["Total", `$${item.total}`];
            summaryRows.push(summaryData);

            doc.autoTable({
                columns: tableColumnSummary,
                body: summaryRows,
                startY: finalY + 25,
                columnStyles: {1: {halign: 'right'}}
            });

            const tableHeaders = ["Description", "Amount", "Date"];

            if (item.spendingAccounts.length > 0) {
                const spendingAccountsRows = [];

                item.spendingAccounts.forEach((val) => {
                    const spendingAccountsData = [
                        val.description,
                        `$${val.amount}`,
                        val.date,
                    ];
                    spendingAccountsRows.push(spendingAccountsData);
                });

                finalY = doc.previousAutoTable.finalY;

                doc.setFontSize(12);
                doc.text("Spending Accounts Report", 15, finalY + 15);
                doc.autoTable({
                    columns: tableHeaders,
                    body: spendingAccountsRows,
                    startY: finalY + 20,
                    columnStyles: {1: {halign: 'right'}}
                });
            }

            if (item.teladoc.length > 0) {
                const teladocRows = [];

                item.teladoc.forEach((val) => {
                    const teladocData = [
                        val.description,
                        `$${val.amount}`,
                        val.date,
                    ];
                    teladocRows.push(teladocData);
                });

                finalY = doc.previousAutoTable.finalY;

                doc.setFontSize(12);
                doc.text("Teladoc Report", 15, finalY + 15);
                doc.autoTable({
                    columns: tableHeaders,
                    body: teladocRows,
                    startY: finalY + 20,
                    columnStyles: {1: {halign: 'right'}}
                });
            }

            if (item.corehealth.length > 0) {
                const corehealthRows = [];

                item.corehealth.forEach((val) => {
                    const corehealthData = [
                        val.description,
                        `$${val.amount}`,
                        val.date,
                    ];
                    corehealthRows.push(corehealthData);
                });

                finalY = doc.previousAutoTable.finalY;

                doc.setFontSize(12);
                doc.text("Corehealth Report", 15, finalY + 15);
                doc.autoTable({
                    columns: tableHeaders,
                    body: corehealthRows,
                    startY: finalY + 20,
                    columnStyles: {1: {halign: 'right'}}
                });
            }
        });
    }

    const date = Date().split(" ");
    const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];

    // we define the name of our PDF file.
    doc.save(`report_${dateStr}.pdf`);
};
