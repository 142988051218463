export const ownerSidebarData = [
    {
        section: "Reports",
        links: [
            {
                id: 1,
                externalLink: false,
                title: "Dashboard",
                path: "/user/dashboard",
            },
            {
                id: 2,
                externalLink: false,
                title: "Financials",
                path: "/user/financials",
            },
            {
                id: 3,
                externalLink: false,
                title: "Commissions",
                path: "/user/commission",
            },
            {
                id: 4,
                externalLink: false,
                title: "Clients",
                path: "/user/clients?type=policy",
            },
        ],
    },
    {
        section: "Account",
        links: [
            {
                id: 11,
                externalLink: false,
                title: "Profile",
                path: "/user/profile",
            },
            {
                id: 12,
                externalLink: false,
                title: "Advisors",
                path: "/user/advisors",
            },
        ],
    },
    {
        section: "Enrollments",
        links: [
            {
                id: 5,
                externalLink: true,
                title: "Build a quote",
                path: "https://proposal1.healthclaim.ca/",
            },
            {
                id: 6,
                externalLink: false,
                title: "Enrollment Forms",
                path: "/user/enrollments",
            },
        ],
    },
    {
        section: "Marketing",
        links: [
            {
                id: 7,
                externalLink: true,
                title: "Documents",
                path: "https://advisor-resources.nationalhealthclaim.com/marketing.html",
            },
            // {
            //     id: 8,
            //     externalLink: true,
            //     title: "Shareable Videos",
            //     path: "https://advisor-resources.nationalhealthclaim.com/marketing.html",
            // },
            // {
            //     id: 9,
            //     externalLink: true,
            //     title: "Articles",
            //     path: "https://advisor-resources.nationalhealthclaim.com/ideas.html?id=b1fc9da9-9948-408a-b007-0573f0801f92",
            // },
        ],
    },

];

export const advisorSidebarData = [
    {
        section: "Account",
        links: [
            {
                id: 1,
                externalLink: false,
                title: "Profile",
                path: "/user/profile",
            },
            {
                id: 2,
                externalLink: false,
                title: "Clients",
                path: "/user/companies",
            },
        ],
    },
    {
        section: "Enrollments",
        links: [
            {
                id: 5,
                externalLink: true,
                title: "Build a quote",
                path: "https://proposal1.healthclaim.ca/",
            },
            {
                id: 6,
                externalLink: false,
                title: "Enrollment Forms",
                path: "/user/enrollments",
            },
        ],
    },
    {
        section: "Marketing",
        links: [
            {
                id: 7,
                externalLink: true,
                title: "Documents",
                path: "https://advisor-resources.nationalhealthclaim.com/marketing.html",
            },
            // {
            //     id: 8,
            //     externalLink: true,
            //     title: "Shareable Videos",
            //     path: "https://advisor-resources.nationalhealthclaim.com/marketing.html",
            // },
            // {
            //     id: 9,
            //     externalLink: true,
            //     title: "Articles",
            //     path: "https://advisor-resources.nationalhealthclaim.com/ideas.html?id=b1fc9da9-9948-408a-b007-0573f0801f92",
            // },
        ],
    },
];
