import "./pagination.style.scss";

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
  const pageNumbers = [];
  const lastPage = Math.ceil(totalItems / itemsPerPage);
  if (lastPage < 8) {
    for (let i = 2; i <= Math.ceil(totalItems / itemsPerPage) - 1; i++) {
      pageNumbers.push(i);
    }
  } else if (currentPage < 4) {
    for (let i = 2; i < 5; i++) {
      pageNumbers.push(i);
    }
  } else if (currentPage > lastPage - 3) {
    for (let i = lastPage - 3; i < lastPage; i++) {
      pageNumbers.push(i);
    }
  } else {
    pageNumbers.push(currentPage - 1);
    pageNumbers.push(currentPage);
    pageNumbers.push(currentPage + 1);
  }

  return (
    <nav className=" table-responsive">
      <ul className="pagination justify-content-center">
        <li
          key={0}
          className={`page-item ${currentPage === 1 ? " disabled" : null}`}
        >
          <span
            className="page-link"
            aria-label="Previous"
            onClick={() => paginate(0)}
          >
            <span aria-hidden="true">&laquo;</span>
          </span>
        </li>
        <li
          key={1}
          className={`page-item ${currentPage === 1 ? " active" : null}`}
        >
          <span onClick={() => paginate(1)} className="page-link">
            {1}
          </span>
        </li>
        {currentPage > 3 && lastPage > 7 ? (
          <li className="page-item">
            <span className="page-link">...</span>
          </li>
        ) : null}
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? " active" : null}`}
          >
            <span onClick={() => paginate(number)} className="page-link">
              {number}
            </span>
          </li>
        ))}
        {currentPage < lastPage - 2 && lastPage > 7 ? (
          <li className="page-item">
            <span className="page-link">...</span>
          </li>
        ) : null}
        <li
          key={lastPage}
          className={`page-item ${currentPage === lastPage ? " active" : null}`}
        >
          <span onClick={() => paginate(lastPage)} className="page-link">
            {lastPage}
          </span>
        </li>
        <li
          key={totalItems.length + 1}
          className={`page-item ${
            currentPage === lastPage ? " disabled" : null
          }`}
        >
          <span
            className={"page-link"}
            aria-label="Next"
            aria-disabled="true"
            onClick={() => paginate(totalItems + 10)}
          >
            <span aria-hidden="true">&raquo;</span>
          </span>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
