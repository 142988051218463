import React, {useCallback, useState, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import {useParams} from 'react-router-dom';

const FileUploader = ({id, onFileUpload}) => {
    const [files, setFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL_PROD : process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LOCAL;

    useEffect(() => {
        fetchFiles();
    }, []);

    const fetchFiles = async () => {
        const response = await fetch(`${apiUrl}/enrollment/getenrollmentfiles/${id}`);
        if (response.ok) {
            const files = await response.json();
            setUploadedFiles(files);
            onFileUpload(files.length > 0);
        } else {
            console.error('Failed to fetch files');
        }
    };

    const handleFileClick = (e,fileId) => {
        e.preventDefault();
        window.open(`${apiUrl}/enrollment/downloadenrollmentfiles/${fileId}`, '_blank');
    };

    const onDrop = useCallback(acceptedFiles => {
        const newFiles = acceptedFiles.map(file => ({
            file,
            EnrollmentId: id,
            FileName: file.name,
            FileType: file.name.split('.').pop()
        }));
        setFiles(newFiles);
        onFileUpload(newFiles.length > 0);
    }, [id, onFileUpload]);

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel': ['.xls'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'text/plain': ['.txt'],
            'application/vnd.apple.numbers': ['.numbers'],
            'application/pdf': ['.pdf'],
        },
        maxSize: 10 * 1024 * 1024  // 10 MB
    });

    const uploadFiles = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        const enrollments = files.map(({file, EnrollmentId, FileName, FileType}) => ({
            EnrollmentId: id,
            FileName,
            FileType
        }));
        files.forEach(({file}) => {
            formData.append('files', file);
        });
        formData.append('enrollmentsJson', JSON.stringify(enrollments));

        try {
            const response = await fetch(`${apiUrl}/enrollment/uploadfile`, {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                const result = await response.json();
                console.log('Files uploaded successfully:', result);
                setFiles([]);
                fetchFiles();
            } else {
                throw new Error('Failed to upload files');
            }
        } catch (error) {
            console.error('Error uploading files:', error.message);
        }
    };

    const removeFile = (e, fileToRemove) => {
        e.preventDefault();
        const updatedFiles = files.filter(file => file !== fileToRemove);
        setFiles(updatedFiles);
        onFileUpload(updatedFiles.length > 0);
    };

    const deleteFromDatabase = async (e, file) => {
        e.preventDefault();
        try {
            const response = await fetch(`${apiUrl}/enrollment/deleteenrollmentfile/${file.id}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                fetchFiles();
            } else {
                throw new Error('Failed to delete file');
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            alert('Error deleting file: ' + error.message);
        }
    };

    return (
        <div>
            <div {...getRootProps()} style={{border: '2px dashed #ccc', padding: '20px', cursor: 'pointer'}}>
                <input {...getInputProps()} />
                <p>Drag and drop your files here, or click to select files</p>
            </div>
            <div>
                {files.length > 0 && (
                    <div className="my-3">
                        <h6>Selected Files to Upload</h6>
                        <ul>
                            {files.map(file => (
                                <li key={file.FileName}>
                                    {file.FileName}
                                    <button className="btn btn-outline-danger btn-sm mx-3"
                                            onClick={(e) => removeFile(e, file)}>Remove
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            {
                uploadedFiles.length > 0 && (<div className="my-3">
                    <h6>Uploaded Files</h6>
                    <ul>
                        {uploadedFiles.map(file => (
                            <li key={file.id}>
                                <a href="#"  onClick={(e) => handleFileClick(e,file.id)}>{file.fileName}</a>
                                <button className="btn btn-outline-danger btn-sm mx-3"
                                        onClick={(e) => deleteFromDatabase(e, file)}>Remove
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>)
            }

            <button className="btn btn-primary mt-2" onClick={uploadFiles} disabled={!files.length}>Upload</button>
        </div>
    );
};

export default FileUploader;
