import PageWrapper from "../../components/page-wrapper/page-wrapper.components";
import FinancialSection from "../../components/financials/financials.component";

const FiancialsPage = () => {
  return (
    <PageWrapper>
      <FinancialSection />
    </PageWrapper>
  );
};

export default FiancialsPage;
