import styled from "styled-components";

export const SectionContainer = styled.div`
  position: relative;
  margin: ${(props) => (props.card ? "2em 2em 2em 0em" : "2em auto;")};
  background-color: #fff;
  padding: 15px 15px 15px 15px;
  box-shadow: 0 0 11px rgb(33 33 33 / 20%);
  border-radius: 8px;
  width: ${(props) => (props.fullWidth ? "100%" : props.card ? "30%" : "90%")};
  max-width: 1200px;
    height: 100%;

  @media (max-width: 640px) {
    width: 95%;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
  color: black;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const SectionWrapper = ({ children, ...props }) => (
  <SectionContainer fullWidth={props.fullWidth} card={props.card}>
    <TitleContainer>{props.title}</TitleContainer>
    {children}
  </SectionContainer>
);
