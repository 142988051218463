import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../../assets/nhclogo.png";

export const formatData = (report) => {
    let data = [];
    Object.keys(report).forEach((item) => {
        if (item === "spendingAccounts" || item === "teladoc" || item === "corehealth") {
            data.push(...report[item]);
        } else {
            if (item === "teladocTotal" || item === "corehealthTotal" || item === "spendingAccountsTotal") {
                if (parseFloat(report[item]) > 0) {
                    data.push({[item]: report[item]});
                }
            } else {
                data.push({[item]: report[item]});
            }
        }
    });
    return data;
};

export const generatePDF = (report) => {
    console.log(report);
    // initialize jsPDF
    const doc = new jsPDF();

    jsPDF.autoTableSetDefaults({
        theme: "striped",
        tableWidth: "auto",
        columnWidth: "auto",
        showHead: "everyPage",
        rowPageBreak: "avoid",
        pageBreak: "auto",
        didDrawPage: function (data) {
            //header
            doc.addImage(Logo, "PNG", 115, 5, 90, 7);

            //footer
            let str = `${doc.internal.getNumberOfPages()}`;
            doc.setFontSize(10);
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height
                ? pageSize.height
                : pageSize.getHeight();
            const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
            doc.text(str, pageWidth / 2, pageHeight - 2);
        },
        margin: {top: 30, bottom: 20},
        styles: {
            overflow: "linebreak",
        },
        columnStyles: {1: {halign: "center", cellWidth: 20}},
        headStyles: {fillColor: "#223e7f"},
    });

    const width = doc.internal.pageSize.getWidth();
    doc.setFontSize(15);
    doc.text(
        ` Commission Report - ${report.company || report.agentReportingCode}`,
        width / 2,
        25,
        {
            align: "center",
        }
    );

    const tableColumnSummary = ["Type", "Amount"];
    const summaryRows = [];

    let summaryData = [];
    if (parseFloat(report.spendingAccountsTotal) > 0) {
        summaryData = ["Spending Accounts Total", `$${report.spendingAccountsTotal}`];
        summaryRows.push(summaryData);
    }

    if (parseFloat(report.teladocTotal) > 0) {
        let teladoc = ["Teladoc Total", `$${report.teladocTotal}`];
        summaryRows.push(teladoc);
    }

    if (parseFloat(report.corehealthTotal) > 0) {
        let corehealth = ["Corehealth Total", `$${report.corehealthTotal}`];
        summaryRows.push(corehealth);
    }

    summaryData = ["Total", `$${report.total}`];
    summaryRows.push(summaryData);

    doc.setFontSize(12);
    doc.text("Summary report", 15, 40);
    doc.autoTable({
        columns: tableColumnSummary,
        body: summaryRows,
        // tableWidth: 100,
        startY: 45,
        columnStyles: {1: {halign: 'right'}}
    });

    let finalY = doc.previousAutoTable.finalY;
    const tableHeaders = ["Description", "Amount", "Date"];

    if (report.spendingAccounts.length > 0) {
        const spendingAccountsRows = [];

        report.spendingAccounts.forEach((item) => {
            const spendingAccountsData = [
                item.description,
                `$${item.amount}`,
                item.date,
            ];
            spendingAccountsRows.push(spendingAccountsData);
        });

        finalY = doc.previousAutoTable.finalY;

        doc.setFontSize(12);
        doc.text("Spending Accounts Report", 15, finalY + 15);
        doc.autoTable({
            columns: tableHeaders,
            body: spendingAccountsRows,
            startY: finalY + 20,
            columnStyles: {1: {halign: 'right'}}
        });
    }

    if (report.teladoc.length > 0) {
        const teladocRows = [];

        report.teladoc.forEach((item) => {
            const teladocData = [
                item.description,
                `$${item.amount}`,
                item.date,
            ];
            teladocRows.push(teladocData);
        });

        finalY = doc.previousAutoTable.finalY;

        doc.setFontSize(12);
        doc.text("Teladoc Report", 15, finalY + 15);
        doc.autoTable({
            columns: tableHeaders,
            body: teladocRows,
            startY: finalY + 20,
            columnStyles: {1: {halign: 'right'}}
        });
    }

    if (report.corehealth.length > 0) {
        const corehealthRows = [];

        report.corehealth.forEach((item) => {
            const corehealthData = [
                item.description,
                `$${item.amount}`,
                item.date,
            ];
            corehealthRows.push(corehealthData);
        });

        finalY = doc.previousAutoTable.finalY;

        doc.setFontSize(12);
        doc.text("Corehealth Report", 15, finalY + 15);
        doc.autoTable({
            columns: tableHeaders,
            body: corehealthRows,
            startY: finalY + 20,
            columnStyles: {1: {halign: 'right'}}
        });
    }

    const date = Date().split(" ");
    const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];

    // we define the name of our PDF file.
    doc.save(`report_${dateStr}.pdf`);
};
