import {logoBase64, signatureBase64} from "../../logos";

import pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import {darceyOliverFont} from "../../fonts";
import Button from "react-bootstrap/Button";


pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake.vfs["DarceyOliver.otf"] = darceyOliverFont;

pdfMake.fonts = {
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
    }, DarceyOliver: {
        normal: 'DarceyOliver.otf'
    }
}

const PDFGenerator = ({ selectedPlan, categories, employees, companyInfo, accountConfig, policyName, selectedPADOption, isDrawing, signature, imageURL, adminFee, enrollmentFee, onPdfGenerated }) => {
    const logo = logoBase64;
    const signatureImage = signatureBase64;
    let header = "Enrollment Form" + selectedPlan;
    let planLabel = "";
    if (selectedPlan === "hsa") {
        header = "Health Spending Account Enrollment Form";
        planLabel = " (HSA)";
    } else if (selectedPlan === "lsa") {
        header = "Lifestyle Spending Account Enrollment Form";
        planLabel = " (LSA)";
    } else if (selectedPlan === "fixed") {
        header = "Health and Lifestyle Spending Account Enrollment Form";
        planLabel = " (HSA and LSA)";
    } else if (selectedPlan === "flex") {
        header = "Flexible Spending Account Enrollment Form";
        planLabel = " (Flex)";
    }

    let categoryTable = [[{text: '', fillColor: '#223e7f', color: '#fff'},
        {text: 'Category Name', fillColor: '#223e7f', color: '#fff'}, 
        {
            text: 'Job Description',
            fillColor: '#223e7f',
            color: '#fff',
        }, 
        {text: 'Limit Single', fillColor: '#223e7f', color: '#fff'}, {
            text: 'Limit Couple',
            fillColor: '#223e7f',
            color: '#fff'
        }, 
        {text: 'Limit Family', fillColor: '#223e7f', color: '#fff'}]];

    categories.forEach((category, index) => {
        categoryTable.push([index+1, category.categoryName, category.jobDescription, category.limitSingle, category.limitCouple, category.limitFamily]);
    });
    
    let categoryDetailsTable = [[
        {text: '', fillColor: '#223e7f', color: '#fff'},
        {text: 'Category Name', fillColor: '#223e7f', color: '#fff'},
        {text: 'Waiting Period', fillColor: '#223e7f', color: '#fff'},
        {text: 'Prorate Existing Employees', fillColor: '#223e7f', color: '#fff'},
        {text: 'Prorate New Hires', fillColor: '#223e7f', color: '#fff'},
        {text: 'Coverage Rate', fillColor: '#223e7f', color: '#fff'},
    ]];

    if (selectedPlan !== "hsa") {
        categoryDetailsTable[0].push({text: 'Allow LSA Dependent Claims', fillColor: '#223e7f', color: '#fff'});
    }
    if (selectedPlan === "flex") {
        categoryDetailsTable[0].push({text: 'Flex Plans', fillColor: '#223e7f', color: '#fff'});
        categoryDetailsTable[0].push({text: 'Default Split', fillColor: '#223e7f', color: '#fff'});
    }

    categories.forEach((category, index) => {
        let categoryRow = [index+1, category.categoryName, category.waitingPeriod, category.prorateExistingEmployees ? 'Yes' : 'No', category.prorateNewHires ? 'Yes' : 'No', category.coverageRate + '%'];

        if (selectedPlan !== "hsa") {
            categoryRow.push(category.allowLsaDependents ? 'Yes' : 'No');
        }

        if (selectedPlan === "flex") {
            let selectedPlans = [];
            if (category.hasHsa) selectedPlans.push('HSA');
            if (category.hasLsa) selectedPlans.push('LSA');
            if (category.hasRsp) selectedPlans.push('RSP');
            categoryRow.push(selectedPlans.join(', '));
            categoryRow.push(category.defaultSelectionSplit || "" + " " + category.defaultSelectionNote || "");
        }

        categoryDetailsTable.push(categoryRow);
    });

    let configDesc = ["When the plan is to start.  The plan can be back-dated up to one year (will apply to all employees)", "The 12 month cycle that claims are made against.  You can align it to your fiscal year or keep it to a calendar year.", "Number of days from start of new benefit year during which claims can be made against the previous year.", "Credit Carry:  Unused credits from one benefit year can transfer to the next year after the runoff period has ended. Expense Carry:  Expenses (receipts) from one benefit year can be claimed in the next year, after the runoff period has ended. No Carry:  Credits must be used within each benefit year only. No carry forward after the selected run-off period", "Child dependents remain eligible until, and including, this age.", "Child dependents attending full-time post secondary school remain eligible until (and including) this age. ", "The date employees should receive their first Flex Selection emails.", "This date is simply the last day that employees are able to make their selection. After this date they will default to the policy’s chosen Default split, which can be found in the assigned benefit category details."]

    let toTitleCase = (str) => {
        return str
            .replace(/([A-Z])/g, ' $1')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    let configTableBody = [];
    let keys = Object.keys(accountConfig);
    let lastKeyIndex = keys.length - 1;
    let i = 0;
    for (let [key, value] of Object.entries(accountConfig)) {
        if (keys.indexOf(key) !== lastKeyIndex) {
            configTableBody.push([{text: toTitleCase(key)}, {text: value}, {text: configDesc[i]}]);
            i++;
        }
    }

    let employeeContent = employees.map((employee, index) => {
        let employeeLabel = {
            text: 'Employee: ' + employee.firstName + ' ' + employee.lastName, fontSize: 10, color: '#223e7f', bold: true, margin: [0, 10, 0, 10]
        };
        
        let employeeDetailsTable = [[{text: 'First Name', noWrap: true}, {text: 'Last Name', noWrap: true}, {text: 'Maiden Name', noWrap: true}, {text: 'Nickname', noWrap: true}, {text: 'Email', noWrap: true}, {text:'Date of Birth', noWrap: true}, {text: 'Hire Date', noWrap: true}, {text: 'Category', noWrap: true}]];
        employeeDetailsTable.push([employee.firstName || "", employee.lastName || "", employee.maidenName || "", employee.nickName || "", employee.email || "", employee.birthDate || "", employee.hireDate || "", employee.categoryAssign || ""]);

        let dependentsLabel = {
            text: 'Dependents ', fontSize: 9, bold: true, margin: [0, 10, 0, 0]
        };
        
        let dependentDetailsTable = [];
        dependentDetailsTable.push(['', {text: 'First Name', noWrap: true}, {
                text: 'Last Name',
                noWrap: true
        }, {text: 'Maiden Name', noWrap: true}, {text: 'Nickname', noWrap: true}, {
            text: 'Date of Birth',
            noWrap: true
        }, {text: 'Relationship', noWrap: true}]);
        employee.dependents.forEach((dependent, index) => {
            let dependentRow = [index + 1, {
                text: dependent.firstName,
                noWrap: true
            }, dependent.lastName, dependent.maidenName, dependent.nickName, dependent.birthDate, dependent.relation + dependent.studentNotes];
            dependentDetailsTable.push(dependentRow)
        });

        let canvasObject = {
            canvas: [{
                type: 'rect', x: 0, y: 0, w: 515.28, h: 2, color: '#223e7f', margin: [0, 5, 0, 5],
            }]
        };

        return [
            employeeLabel,
            {
                table: {
                    widths: ['12.5%', '12.5%', '12.5%', '12.5%', '12.5%', '12.5%', '12.5%', '12.5%'],
                    body: employeeDetailsTable
                },
                fontSize: 8,
                margin: [0, 5],
                layout: 'lightHorizontalLines'
            },
            employee.dependents.length !== 0 ? dependentsLabel : "",
            employee.dependents.length !== 0 ?
            {
                table: {
                    widths: ['1%', '15.5%', '15.5%', '15.5%', '15.5%', '15.5%', '15.5%'],
                    body: dependentDetailsTable,
                },
                fontSize: 8,
                margin: [0, 5],
                layout: 'lightHorizontalLines'
            } : {},
            canvasObject
        ]
    });
    
    const today = new Date();
    const day = today.getDate();
    const month = today.toLocaleString('default', {month: 'long'});
    const year = today.getFullYear();

    let indemnityContract = {
        // text: ['An Indemnity Contract Providing for the Administration of a', selectedPlan === 'hsa' ? ' Health ' : '', selectedPlan === 'lsa' ? ' Lifestyle ' : '', selectedPlan === 'fixed' ? ' Health and Lifestyle ' : '', selectedPlan === 'flex' ? ' Flexible ' : '', 'Spending Account (Private Health Services Plan) by National HealthClaim'].join(''),
        text: ['An Indemnity Contract and Plan Services Contract Providing for the Administration of a Health Spending Account (Private Health Services Plan) and/or Lifestyle Reimbursement Account by National HealthClaim'].join(''),
        fontSize: 24,
        alignment: 'center',
        margin: [0, 100, 0, 50]
    };

    let indemnityAgreement = {
        text: ['This Administrative Services Indemnity Agreement Made\nas of the ', day, ' day of ', month, ', ', year, '\n\nBetween: ', {
            text: 'National HealthClaim Corporation ("NHC")',
            bold: true
        }, ' and the ', {text: '"Subscriber" ', bold: true}, policyName],
        fontSize: 16,
        alignment: 'center',
        margin: [0, 30, 0, 50],
        pageBreak: 'before'
    };

    let indemnityLegal = [
        {
            text: [{
                text: 'WHEREAS',
                bold: true
            }, ' NHC and the Subscriber wish to enter into an indemnity contract whereby NHC, for consideration, agrees to indemnify certain persons in respect of certain hospital expenses, medical expenses, medical plans or any combination of such expenses or plans;'],
            fontSize: 10,
            alignment: 'left',
            margin: [0, 0, 0, 10]
        }, {
            text: [{
                text: 'NOW THEREFORE THIS AGREEMENT WITNESSETH',
                bold: true
            }, ' that in consideration of the mutual premises and covenants herein contained, the receipt and sufficiency of which is hereby acknowledged, it is agreed that:'],
            fontSize: 10,
            alignment: 'left',
            margin: [0, 0, 0, 10]
        }, {
            text: 'Definitions', fontSize: 12, bold: true, margin: [0, 20, 0, 10], decoration: 'underline'
        }, {
            text: '1. The following definitions shall be used throughout this agreement:',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            ul: [{
                text: ['a. "Administrative Fee" means the fee to be paid by the Subscriber to NHC concurrently with the Eligible Medical Expenditure, as outlined in Schedule “B”, plus any applicable taxes payable thereon, including Goods and Services Tax and any applicable Provincial taxes;'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['b. "Claim Adjudication" means the process whereby NHC adjudicates a claim of a Qualified Person to determine, among other things, whether such claim is a type which is covered by the PHSP and whether such claim has been paid by the Qualified Person;'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['c. "Eligible Medical Services" means only those hospital expenses, medical expenses, medical plans or any combination of such expenses or plans which, pursuant to the interpretation by NHC of the Income Tax Act (Canada), including section 248(1) and section 118.2(2) thereof and regulations thereunder, may be included in the PHSP;'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['d. "Eligible Medical Expenditure" means expenditures in respect of Eligible Medical Services provided to a Qualified Person, up to maximum amounts as set out in Schedule “A” hereto;'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['e. "Eligible Lifestyle Expenditure" means expenditure in respect of Lifestyle Expenses provided to a Qualified Person, up to maximum amounts as set out in Schedule “A” hereto;'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['f. "Fee Guide" means the Schedule of Administrative Fees which is attached as Schedule “B” hereto, forming part of this Agreement;'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['g. "PHSP" means the private health services plan (known as the Health Spending Account)to be established and administered pursuant to this indemnity contract by NHC on behalf of the Subscriber wherein, upon a Qualified Person making an Eligible Medical Expenditure, the Subscriber shall remit the Premium to NHC, following which, upon a satisfactory Claim Adjudication, NHC shall indemnify the Qualified Person for the cost of the Eligible Medical Expenditure; '],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['h. "Premium" means the sum of the Eligible Medical Expenditure, and/or Eligible Lifestyle Expenditure (depending on the type of plan chosen by the Subscriber), the Administrative Fee, and any applicable taxes payable thereon, including Goods and Services Tax and any applicable Provincial taxes;'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['i. "Qualified Person" means such employees of the Subscriber and other persons as are set out in Schedule “A” hereto who may, depending on the plan offered by the Subscriber, include the spouse or common-law partner of the employee (including same-sex common-law partners) or any member of that employee’s household who is a dependent of that employee, as defined for purposes of the Canada Income Tax Act, so long as there is a contract of employment in good standing.'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            },], 
            margin: [20, 0, 0, 10], listType: 'none',
            fontSize: 10,
        }, {
            text: 'Covenants of the Subscriber',
            fontSize: 12,
            bold: true,
            margin: [0, 20, 0, 10],
            decoration: 'underline'
        }, {
            text: '2. Upon receipt of a claim from a Qualified Person for reimbursement of a Medical Expenditure and/or Lifestyle Expenditure, the Subscriber shall pay the Premium and applicable taxes to NHC, for NHC to perform the Claim Review and Settlement process.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: '3. The Subscriber shall promptly provide funding to settle approved claims with payment options and processing policy outlined in Schedule “C” hereto.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: '4. The Subscriber shall keep NHC claim processing systems current as described in Schedule “A” hereto.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: 'Covenants of NHC', fontSize: 12, bold: true, margin: [0, 20, 0, 10], decoration: 'underline'
        }, {
            text: '5. Will commence the Claim Adjudication process upon receipt of the claim details either through the mobile application (ClaimSnap), the online portal, or via post mail, subject to the funding policy in Schedule “C” hereto.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: '6. If the Subscriber pays through Pre-authorized Debit (PAD), direct deposit, online payment, or wire transfer, NHC shall follow the steps listed in paragraph 8.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: '7. In the event that, through the Claim Adjudication process, NHC determines that the claim is one which is covered by the Health Spending Account, it shall:',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: '8. Subject to paragraph 6 and 7 herein, upon receipt of the Premium NHC shall:',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            ul: [{
                text: ['a. maintain the Premium on behalf of the Subscriber until such time as NHC either:'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['i. pays the amount of the Eligible Medical Expenditure and/or Eligible Lifestyle Expenditure to the Qualified Person and subsequently transfers the Administrative Fee to itself, all in accordance with paragraph 6 and 7 herein; or'],
                margin: [20, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['ii. returns the Premium to the Subscriber pursuant to paragraph 10 herein.'],
                margin: [20, 0, 0, 10],
                listType: 'none'
            },], margin: [20, 0, 0, 10], listType: 'none', fontSize: 10,
        }, {
            text: '9. In the event that, through the Claim Adjudication process, NHC determines that the claim is one which is covered by the Spending Account, it shall:',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            ul: [{
                text: ['a. indemnify that Qualified Person in respect of such claim by issuing a payment through direct deposit or cheque in the amount of such Eligible Medical Expenditure and/or Eligible Lifestyle Expenditure to such Qualified Person;'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['b. issue a Premium statement/invoice to the Subscriber; and\n'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['c. transfer the Administrative Fee to its own account'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            },], margin: [20, 0, 0, 10], listType: 'none', fontSize: 10,
        }, {
            text: '10. In the event that, through the Claim Adjudication process, NHC determines for any reason that the claim is not one which is covered by the Spending Account, it shall either return the Premium to the Subscriber (if requested by the Subscriber), or hold the balance of Premium in the Subscriber’s account for future use, if funds are already received. NHC will then inform the Qualified Person with an explanation of why the claim is not covered.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: '11. NHC shall use commercially reasonable efforts to ensure that the Claim Adjudication process and the indemnification of a Qualified Person (if required) are completed in a timely manner, subject to Schedule “C” hereto.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: '12. NHC shall not become entitled to the Administrative Fee unless and until the cheque or payment in the amount of the Eligible Medical Expenditure (if required) and/or Eligible Lifestyle Expenditure (if required) has been issued to the Qualified Person.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: '13. NHC reserves the right to change the Administrative Fee on 60 days’ written notice to the Subscriber.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: 'Conditions Precedent', fontSize: 12, bold: true, margin: [0, 20, 0, 10], decoration: 'underline'
        }, {
            text: '14. Conditions precedent to the performance of this indemnity agreement are that:\n',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            ul: [{
                text: ['a. there shall be a contract of employment in good standing between the Subscriber and the Qualified Person or the person through whom such Qualified Person makes a claim pursuant to the Spending Account; and'],
                margin: [10, 0, 0, 10],
                listType: 'none', 
                fontSize: 10,
            }, {
                text: ['b. the Subscriber shall have an undertaking or a contractual obligation to the Qualified Persons, and each of them, to indemnify such Qualified Persons in respect of Eligible Medical Expenses and/or Eligible Lifestyle Expenses; and that by submitting a Premium and documentation to NHC pursuant to paragraph 2 herein, the Subscriber represents to NHC that such conditions precedent exist.'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            },], margin: [20, 0, 0, 10], listType: 'none', fontSize: 10,
        }, {
            text: 'Enforcement by Qualified Persons',
            fontSize: 12,
            bold: true,
            margin: [0, 20, 0, 10],
            decoration: 'underline'
        }, {
            text: '15. NHC agrees that Qualified Persons are entitled to enforce NHC’s obligations to indemnify them pursuant to this agreement notwithstanding that such Qualified Persons are not named, nor have they signed, as parties to this agreement and that in the event of such enforcement, NHC shall not raise the issue of privity of contract.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: 'Termination of Agreement',
            fontSize: 12,
            bold: true,
            margin: [0, 20, 0, 10],
            decoration: 'underline'
        }, {
            text: '16. The contract may be terminated',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            ul: [{
                text: ['a. By NHC giving the Subscriber 15 days notice of termination by email.'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['b. By the Subscriber at any time on request, subject to no outstanding claim payment funding.'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            },], margin: [20, 0, 0, 10], listType: 'none', fontSize: 10,
        }, {
            text: '17. If the contract is terminated by NHC',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            ul: [{
                text: ['a. NHC must refund the excess Premium in the Subscriber’s account that was held for future claim reimbursement; and'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['b. The refund must accompany the notice unless the Premium is subject to adjustment or determination as to amount, in which case the refund must be made as soon as practicable.'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            },], margin: [20, 0, 0, 10], listType: 'none', fontSize: 10,
        }, {
            text: '18. If the contract is terminated by the Subscriber (company/policy holder), NHC must refund as soon as practicable the excess Premium in the Subscriber’s account that was held for future claim reimbursement.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: '19. The 15 day period referred to in subparagraph 16.a of this condition starts to run on the day the email is delivered to the Subscriber’s address.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: 'General', fontSize: 12, bold: true, margin: [0, 20, 0, 10], decoration: 'underline'
        }, {
            text: '20. This Agreement shall be governed by and construed in accordance with the laws of the Province of Alberta and the laws of Canada applicable therein.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: '21. The Parties agree that the within agreement is an indemnity contract in respect of hospital expenses, medical expenses, lifestyle expenses, or any combination of such expenses pursuant to the Canada Income Tax Act, including section 248(1) therein and that they will construe and interpret this agreement accordingly.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: '22. Headings used in this Agreement are used for convenience only and shall not form the basis for any interpretation of the terms of intent of this Agreement.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: '23. If one or more of the provisions of this agreement or any part of them is, or adjudged to be, invalid, illegal or unenforceable in any respect, the validity, legality and enforceability of the remaining provisions hereof shall not in any way be affected or impaired thereby, and such invalid, illegal or unenforceable provision or part shall be deemed to be severable.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: '24. The Subscriber acknowledges that it has had the opportunity to obtain its own legal and tax advice with respect to this agreement.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: '25. Schedules “A”, “B”, and “C” referred to herein and attached hereto are incorporated by reference to and form part of this agreement.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: '26. This agreement may be executed and delivered in separate counterparts and by electronic means, each of which when so executed and delivered shall constitute the one in the same instrument.',
            fontSize: 10,
            margin: [0, 0, 0, 10]
        }, {
            text: `Signed this ${day} day of ${month}, ${year}.`, fontSize: 10, bold: true, margin: [0, 20, 0, 30]
        }]

    let eligibleExpensesContent = [];
    if (selectedPlan !== "hsa") {
        eligibleExpensesContent.push({
            text: 'Eligible LSA Expenses', fontSize: 16, bold: true, margin: [0, 20, 0, 10], color: '#223e7f'
        });

        eligibleExpensesContent.push({
            ul: companyInfo.eligibleLifestyleExpenses.map(expense => ({
                text: expense, fontSize: 10, margin: [0, 0, 0, 10]
            })), margin: [20, 0, 0, 10]
        });
    }

    let getSignatureContent = (isDrawing, signature, imageURL, signatureImage) => {
        if (isDrawing) {
            return {
                image: imageURL, width: 150,
            };
        } else {
            return {
                text: signature, font: 'DarceyOliver', fontSize: 100
            };
        }
    }
    
    const generate = () => {
        let docDefinition = {
            header: function (currentPage, pageCount) {
                return [{
                    canvas: [{
                        type: 'rect', x: 0, y: 0, w: 595.28, h: 15, color: '#223e7f'
                    }]
                }];
            }, content: [{
                image: logo, width: 200, alignment: 'left'
            }, {
                text: header, font: 'Roboto', fontSize: 22, margin: [0, 20, 0, 30]
            }, {
                text: ['A. Company Information'],
                font: 'Roboto',
                fontSize: 16,
                margin: [0, 0, 0, 6],
                bold: true,
                color: '#223e7f'
            }, {
                canvas: [{
                    type: 'rect', x: 0, y: 0, w: 515.28, h: 5, color: '#223e7f',
                }]
            }, {
                margin: [0, 20, 0, 20], table: {
                    widths: ['30%', '70%'], body: [[{text: 'Company Name', bold: true}, {
                        text: policyName, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Doing Business As', bold: true}, {
                        text: companyInfo.dba, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Address 1', bold: true}, {
                        text: companyInfo.address1, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Address 2', bold: true}, {
                        text: companyInfo.address2, decoration: 'underline', alignment: 'left'
                    }], [{text: 'City', bold: true}, {
                        text: companyInfo.city, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Province', bold: true}, {
                        text: companyInfo.province, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Postal Code', bold: true}, {
                        text: companyInfo.companyPostal, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Advisor Name', bold: true}, {
                        text: companyInfo.brokerName, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Advisor Email', bold: true}, {
                        text: companyInfo.brokerEmail, decoration: 'underline', alignment: 'left'
                    }], [{
                        text: 'Plan Administrator', bold: true
                    }, {
                        text: accountConfig.companyPlanAdmin.firstName + " " + accountConfig.companyPlanAdmin.lastName,
                        decoration: 'underline',
                        alignment: 'left'
                    }], [{text: 'Plan Admin Email', bold: true}, {
                        text: accountConfig.companyPlanAdmin.email, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Funding Option', bold: true}, {
                        text: selectedPADOption, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Enrollment Notes', bold: true}, {
                        text: companyInfo.companyNotes, decoration: 'underline', alignment: 'left'
                    }],]
                }, layout: 'noBorders'
            }, {
                text: ['B. Employee Benefit Categories' + planLabel],
                font: 'Roboto',
                fontSize: 16,
                margin: [0, 0, 0, 6],
                bold: true,
                color: '#223e7f'
            }, {
                canvas: [{
                    type: 'rect', x: 0, y: 0, w: 515.28, h: 5, color: '#223e7f',
                }]
            }, {
                text: 'Choose job classification(s) for the employees of your company. It is required that each employee within a classification be extended the same annual limits. Please make sure the descriptions are accurate.',
                font: 'Roboto',
                margin: [0, 20, 0, 20],
            }, {
                table: {
                    widths: ['1%', '20%', '*', '10%', '10%', '10%'], 
                    body: categoryTable
                },
                margin: [0, 0, 0, 20],
                fontSize: 8,
            },
            {
                table: {
                    widths: ['1%', '*', '*', '*', '*', '*', '*', '*', '*'],
                    body: categoryDetailsTable
                },
                margin: [0, 0, 0, 20],
                fontSize: 8,
                pageBreak: 'after',
            },
            {
                table: {
                    widths: ['20%', '10%', '*'], 
                    body: [
                        [
                            { text: 'Account Configuration', colSpan: 3, alignment: 'center', fillColor: '#223e7f', color: '#fff' }, {}, {},
                        ], 
                        ...configTableBody
                    ]
                }, 
                fontSize: 8,
            }, 
            ...eligibleExpensesContent, 
            {
                text: ['C. Employee & Dependent Information'],
                font: 'Roboto',
                fontSize: 16,
                margin: [0, 20, 0, 6],
                bold: true,
                color: '#223e7f'
            }, {
                canvas: [{
                    type: 'rect', x: 0, y: 0, w: 515.28, h: 5, color: '#223e7f',
                }]
            },
            ...employeeContent,
            {
                image: logo, width: 200, alignment: 'left', pageBreak: 'before', margin: [0, 20, 0, 20],
            }, indemnityContract, indemnityAgreement, indemnityLegal,
                // Signature
                {
                    table: {
                        widths: ['*', '*'], body: [[{
                            text: 'NATIONAL HEALTHCLAIM CORP.\n\nDavid Howard', fontSize: 12, margin: [0, 0, 0, 10]
                        }, {
                            text: 'the SUBSCRIBER\n\n' + policyName, fontSize: 12, margin: [0, 0, 0, 10]
                        }], [{
                            canvas: [{
                                type: 'line', x1: 0, y1: 0, x2: 170, y2: 0, lineWidth: 1
                            }], margin: [0, 0, 0, 10]
                        }, {
                            canvas: [{
                                type: 'line', x1: 0, y1: 0, x2: 170, y2: 0, lineWidth: 1
                            }], margin: [0, 0, 0, 10]
                        }], [{
                            text: 'Per: (Print full name of Officer)', fontSize: 10, margin: [0, 0, 0, 10]
                        }, {
                            text: 'Per: (Print full name of Subscriber)', fontSize: 10, margin: [0, 0, 0, 10]
                        }], [{
                            image: signatureImage, width: 150, margin: [0, 0, 0, 10]
                        }, getSignatureContent(isDrawing, signature, imageURL)],

                            [{
                                canvas: [{
                                    type: 'line', x1: 0, y1: 0, x2: 170, y2: 0, lineWidth: 1
                                }], margin: [0, 0, 0, 10]
                            }, {
                                canvas: [{
                                    type: 'line', x1: 0, y1: 0, x2: 170, y2: 0, lineWidth: 1
                                }], margin: [0, 0, 0, 0]
                            }], [{
                                text: '(Signature of Officer)', fontSize: 10, margin: [0, 0, 0, 0]
                            }, {
                                text: '(Signature of Subscriber)', fontSize: 10, margin: [0, 0, 0, 0]
                            }], [{
                                text: '\nDirector', fontSize: 10, margin: [0, 0, 0, 0]
                            }, {
                                text: '', fontSize: 10, margin: [0, 0, 0, ]
                            }], [{
                                canvas: [{
                                    type: 'line', x1: 0, y1: 0, x2: 170, y2: 0, lineWidth: 1
                                }], margin: [0, 0, 0, 0]
                            }, {
                                text: '', fontSize: 10, margin: [0, 0, 0, 0]
                            }], [{
                                text: 'Title of Officer', fontSize: 10, margin: [0, 0, 0, 0]
                            }, {
                                text: '', fontSize: 10, margin: [0, 0, 0, 0]
                            }],]
                    }, layout: 'noBorders'
                },

                // Admin Fee
                {
                    text: '',
                    pageBreak: 'before'
                },
                {
                    text: 'Schedule “A” \nto Administrative Service Indemnity Agreement \nSubscriber Profile, Plan Design, and Employee Eligibility',
                    fontSize: 16,
                    alignment: 'center',
                    margin: [0, 0, 0, 20]
                },
                {
                    text: 'The complete details of the subscriber profile, Health Spending Account plan design, and the employee eligibility information is recorded in the NHC secure web site. It is the responsibility of the subscriber Plan Administrator to keep this information current.',
                    alignment: 'center',
                    margin: [0, 0, 0, 20],
                    fontSize: 10,
                },
                {
                    text: 'Schedule “B” \nto Administrative Service Indemnity Agreement \nFees and Terms – Spending Account',
                    fontSize: 16,
                    alignment: 'center',
                    margin: [0, 0, 0, 20]
                },
                {
                    text: `1. Enrollment Fee of $${enrollmentFee}.`,
                    margin: [0, 0, 0, 10],
                    fontSize: 10,
                },
                {
                    text: `2. NHC and the Subscriber agree to an administration fee of ${adminFee}%.`,
                    margin: [0, 0, 0, 10],
                    fontSize: 10,
                },
                {
                    text: '3. Administration Fee – calculated as follows:',
                    margin: [0, 0, 0, 10],
                    fontSize: 10,
                },
                {
                    ul: [
                        'Is a percentage of the employee benefits paid out under the Spending Account.',
                        'GST + applicable taxes are charged on the administration fee and/or the claim amount, dependent on the employee’s province of residence.',
                        'Further details about the administrative fee can be found within the “Product” section of the Plan Administrator online portal.'
                    ],
                    margin: [20, 0, 0, 10],
                    fontSize: 10,
                },
                {
                    text: '4. Accounts must be funded within 120 days of claims being processed. Accounts not funded within 120 days will be placed on hold. Processing of new claims will automatically resume once funds have been received.',
                    margin: [0, 0, 0, 10],
                    fontSize: 10,
                },
                {
                    text: 'Schedule “C” \nto Administrative Service Indemnity Agreement \nAccount Funding Options and Procedures',
                    fontSize: 16,
                    alignment: 'center',
                    margin: [0, 0, 0, 20]
                },
                {
                    text: '1. The HealthCare Spending Account and/or Lifestyle Spending Account can be funded by the Subscriber in multiple ways with associated claim payment turnaround performance.',
                    margin: [0, 0, 0, 10],
                    fontSize: 10,
                },
                {
                    ul: [
                        'The fastest claim payment turnaround time is achieved using Pre-Authorized Debit (PAD). A weekly PAD pull is performed by NHC to top up the funding balance to a predetermined level. During the time between PAD pulls, claims are paid using the current account funds. A separate PAD agreement is required to enable this service.',
                        'Online payment or Direct Deposit options allow the Subscriber to fund their account as desired through their banking services. Funding credit will be applied upon notification by NHC’s bank and will be available for immediate use.',
                        'Funding with Wire Transfer is supported and will be available for immediate use.',
                        'Funding by cheque is supported, however, a 10-day holding period is required to ensure the funds have cleared.'
                    ],
                    margin: [20, 0, 0, 10],
                    fontSize: 10,
                },
                {
                    text: '2. Subscribers who haven\'t paid submitted claims for more than 120 days because of insufficient balance on hand will face a hold on processing any new claims. Processing of new and held claims will automatically resume once funds have been received.',
                    margin: [0, 0, 0, 10],
                    fontSize: 10,
                }
            ], defaultStyle: {
                font: 'Roboto',
                size: 9
            }
        };
        // pdfMake.createPdf(docDefinition).open();
        return new Promise((resolve, reject) => {
            pdfMake.createPdf(docDefinition).getBuffer(buffer => {
                const byteArray = new Uint8Array(buffer);
                const base64String = btoa(byteArray.reduce((data, byte) => data + String.fromCharCode(byte), ''));
                resolve(base64String);
            });
        });
    }

    const handleGeneratePdf = async () => {
        try {
            const base64String = await generate();
            onPdfGenerated(base64String);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Button variant="success" style={{width: "100px"}} size="lg" onClick={handleGeneratePdf}>Submit</Button>
    );
}

export default PDFGenerator;