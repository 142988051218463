import { DistroActionTypes } from "./distro.types";

const INITIAL_STATE = {
  currentDistro: null,
};

const distroReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DistroActionTypes.SET_CURRENT_DISTRO:
      return {
        ...state,
        currentDistro: action.payload,
      };

    default:
      return state;
  }
};

export default distroReducer;
