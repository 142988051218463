import React from 'react';
import {FaSortDown, FaSortUp} from "react-icons/fa";
import {usePagination, useSortBy, useTable} from 'react-table';
import toastr from "toastr";

export const EnrollmentTable = ({fetchEnrollments, currentItems, handleDeleteClick, currentUser}) => {
    const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL_PROD : process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LOCAL;

    const resendForm = async (row) => {
        try {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id: row.id,
                    email: row.clientEmail,
                    firstName: row.clientFirstName,
                    lastName: row.clientLastName,
                }),
            };
            const response = await fetch(`${apiUrl}/enrollment/send`, requestOptions);
            if (response.ok) {
                const data = await response.json();
                toastr.success("Form successfully sent.");
                setTimeout(() => {
                    fetchEnrollments();
                }, 200)
            } else {
                toastr.error("Failed to send form");
            }
        } catch (err) {
            toastr.error("Failed to send form");
        }
    };

    const downloadPdf = async (id) => {
        try {
            window.open(`${apiUrl}/enrollment/downloadenrollmentpdf/${id}`, '_blank');
        } catch (err) {
            toastr.error("Failed to send form");
        }
    };

    const columns = React.useMemo(() => {
        const baseColumns = [
            {
                Header: 'Company',
                accessor: 'policyName',
            },
            {
                Header: 'Client',
                accessor: (row) => `${row.clientFirstName} ${row.clientLastName}`,
                id: 'client',
            },
            {
                Header: 'Email',
                accessor: 'clientEmail',
            },
            {
                Header: 'Status',
                accessor: (row) => {
                    const status = row.status.toLowerCase();
                    if (status === 'pending') {
                        return 'Unsigned';
                    } else if (status === 'complete') {
                        return 'Enrolled';
                    }
                    return status.charAt(0).toUpperCase() + status.slice(1);
                },
                id: 'status',
            },
            {
                Header: 'Created',
                accessor: 'createdAt',
            },
            {
                Header: 'Sent to Client',
                accessor: (row) => row.lastSent ? new Date(row.lastSent).toISOString().split('T')[0] : 'Unsent',
                id: 'lastSent',
            },
        ];

        if (currentItems.some(item => item.status === "pending")) {
            baseColumns.push({
                Header: 'Send Form',
                accessor: 'id',
                id: 'sendForm',
                Cell: ({row}) => {
                    const id = row.original.id;
                    const lastSent = row.original.lastSent;
                    const status = row.original.status.toLowerCase();
                    if (status === 'pending') {
                        return (
                            <button
                                className="btn btn-outline-success btn-sm"
                                onClick={() => resendForm(row.original)}
                                style={{minWidth: '75px'}}
                            >
                                {lastSent ? 'Resend' : 'Send'}
                            </button>
                        );
                    }
                    return null;
                }
            });
        }

        if (currentItems.some(item => item.status === "complete" || item.status === "signed")) {
            baseColumns.push(
                {
                    Header: 'Signed',
                    accessor: (row) => row.signDate ? new Date(row.signDate).toISOString().split('T')[0] : '',
                    id: 'Signed',
                },);
        }

        baseColumns.push(
            {
                Header: 'View',
                accessor: 'id',
                id: 'view',
                Cell: ({cell}) => {
                    const id = cell.value;
                    const item = currentItems.find(item => item.id === id);
                    if (item) {
                        if (item.status === "pending") {
                            return (
                                <a
                                    href={`/enroll/${id}?mode=edit`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-outline-primary btn-sm"
                                >
                                    View
                                </a>
                            );
                        } else if (item.status === "complete" || item.status === "signed") {
                            return (
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={() => downloadPdf(item.id)}
                                >
                                    View
                                </button>
                            );
                        }
                    }
                    return null;
                    return item && item.status === "pending" ? (
                        <a
                            href={`/enroll/${id}?mode=edit`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-outline-primary btn-sm"
                        >
                            View
                        </a>
                    ) : null;
                },

            });

        if (currentItems.some(item => item.status === "pending")) {
            baseColumns.push({
                Header: 'Delete',
                accessor: 'id',
                id: 'delete',
                Cell: ({cell}) => {
                    const id = cell.value;
                    const item = currentItems.find(item => item.id === id);
                    return item && item.status === "pending" ? (
                        <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={() => handleDeleteClick(id)}
                        >
                            Delete
                        </button>
                    ) : null;
                },
            });
        }

        return baseColumns;
    }, [currentItems, handleDeleteClick]);


    const data = React.useMemo(() => currentItems.filter(item => item.id), [currentItems]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data,
            initialState: {pageIndex: 0},
        },
        useSortBy,
        usePagination
    );

    return (
        <>
            <table {...getTableProps()} className="table table-hover pending-enrollment-table">
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                        {column.isSorted ? (column.isSortedDesc ? <FaSortDown/> : <FaSortUp/>) : ''}
                                    </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} key={row.id}>
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            ))}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className="pagination">
                <button className="pagination-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>
                <button className="pagination-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <button className="pagination-button" onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>
                <button className="pagination-button" onClick={() => gotoPage(pageOptions.length - 1)}
                        disabled={!canNextPage}>
                    {'>>'}
                </button>
                <select
                    className="pagination-select"
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map(size => (
                        <option key={size} value={size}>
                            Show {size}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
};
