import {useState, useEffect} from "react";
import {Link, useParams} from "react-router-dom";

import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import logo from "../../assets/nhclogo.png";

import "./sign-in-page.styles.scss";

const SetPasswordPage = () => {
    let id = useParams();
    const initialState = {
        password: "",
        confirmPassword: "",
        passwordMatch: null,
        passwordToken: id.token,
        success: false
    };

    useEffect(() => {
        verifyToken(id.token);
    }, []);

    const [user, setUser] = useState({...initialState});
    const apiUrl = process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_API_URL_DEV
            : process.env.REACT_APP_API_URL_LOCAL;
    const verifyToken = async (token) => {
        try {
            const response = await fetch(`${apiUrl}/users/validatetoken/${token}`);
            const data = await response.json();

            if (!data.success) {
                // Redirect to sign-in screen
                window.location.href = "/invalid";
            }
        } catch (error) {
            console.log(error);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const requestOptions = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    id: user.id,
                    password: user.password,
                    confirmPassword: user.confirmPassword,
                    passwordToken: id.token
                }),
            };
            const response = await fetch(
                `${apiUrl}/users/setpassword`,
                requestOptions
            );
            const data = await response.json();
            if (data.message) {
                if (data.success) {
                    setUser({...user, success: true});
                    setTimeout(() => {
                        window.location.href = "/signin";
                    }, 1000);
                } else {
                    setUser({...user, message: data.message, success: false});
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (e) => {
        e.target.name === "password"
            ? setUser({...user, password: e.target.value})
            : setUser({...user, confirmPassword: e.target.value});
    };

    return (
        <div className="page__container">
            <div className="form__container">
                <img className="logo" src={logo} alt="Logo"/>
                <h2 className="message">Enter your password</h2>
                <form className="signin__form" onSubmit={handleSubmit}>
                    <input
                        className="signin__input"
                        name="password"
                        type="password"
                        onChange={handleChange}
                        value={user.password}
                        placeholder="Password"
                        required
                    />
                    <input
                        className="signin__input"
                        name="confirmPassword"
                        type="password"
                        onChange={handleChange}
                        value={user.confirmPassword}
                        placeholder="Confirm Password"
                        required
                    />
                    {user.passwordMatch === null ? null : !user.passwordMatch ? (
                        <Alert key={"alert"} variant={"danger"}>
                            Passwords do not match
                        </Alert>
                    ) : null
                    }
                    {
                        user.message != null && user.success ? (
                            <Alert key={"alert"} variant={"success"}>
                                Password has been successfully set. Please login.
                            </Alert>
                        ) : null
                    }
                    {
                        user.message != null && !user.success ? (
                            <Alert key={"alert"} variant={"danger"}>
                                {user.message}
                            </Alert>
                        ) : null
                    }
                    <Button className="signin__button" variant="primary" type="submit">
                        Submit
                    </Button>
                </form>
                <span className="link__container">
          <Link className="link" to="/signin">
            Return to login
          </Link>
        </span>
            </div>
        </div>
    );
};

export default SetPasswordPage;
